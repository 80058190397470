.result-list{
    background-color: rgb(253, 253, 250);
    width:100%;
    margin: 0px;
    padding-left:20px;
    padding-right:10px;
    display:flex;
    flex-direction: column;
    flex:1;
    color:rgb(1, 1, 1);
    font-weight:400;
    box-shadow:30px 10px 98px #111;
    height:auto;
    text-decoration:none; 
    max-height:700px;
    overflow-y:scroll;
cursor:pointer;
position: "-webkit-relative";
position: "relative";
top: 110;
z-index: "110";

}