.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gray;
  margin-top: 150px;
  padding-top: 200px;
  padding-bottom: 20%;
  z-index: 100;
  background-color: black;
  height: 200px;
}
.policies {
  color: rgb(155, 152, 152);
  text-decoration: none;
  margin-bottom: 1px;
  padding-bottom: 1px;
  margin-top: 1px;
  padding-top: 1px;
}
.ads {
  text-decoration: none;
}
