.order{
    padding:40px;
    margin:20px 0;
    border:1px solid lightgray;
    background-color:white;
    position: relative;
}
.order{
   
    top: 40px;
    right:20px;
    }

    .order_total{
        font-weight: 500;
        text-align:left;

    }
    /******/
    .orders{
        padding: 20px 80px;
    }
    .orders>h1{
        margin:30px 0;
    }