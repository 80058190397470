.payment {
  background-color: rgb(252, 242, 242);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment_container > h1 {
  text-align: center;
  padding: 10px;
  font-weight: 400;
  background-color: rgb(234, 237, 237);
  border-bottom: 1px solid lightgray;
}

.payment_container > h1 a {
  text-decoration: none;
}

.payment_section {
  /* each row */
  display: flex; /* spreads the row items horizontally */
  flex-direction: column;
  padding: 20px;
  margin: 0 20px; /* top-bottom and left-right */
  border-bottom: 1px solid lightgray;
}

.payment_title {
  flex: 0.2; /* should occupy 20% of the payment_section ( row )*/
}

.payment_address,
.payment_items,
.payment_details {
  flex: 0.8; /* should occupy 80% of the payment_section ( row )*/
}

.payment_details > form {
  max-width: 900px;
}

.payment_details > h4 {
  padding-bottom: 20px;
}

.payment_details > form > div > button {
  background-color: #f0c14b;
  border-radius: 2px;
  width: 100%;
  text-align: center;
  height: 30px;
  border: 1px solid;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: #a88734;
  color: #111;
}
.payment > form {
  max-width: 100vw;
  justify-items: left;
}
.deliveryForm_container {
  justify-items: center;
  max-width: 100vw;

  margin-left: 100;
}

.card {
  max-width: 100vw;
  background-color: #f9f4e9;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  text-align: center;
  border: 1px solid;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 30px;
  border-color: #a88734;
  color: #111;
}

.deliveryFormErr {
  color: red;
  font-weight: 900;
  font-size: 1rem;
  padding-top: 1;
  margin-bottom: 170;
}
