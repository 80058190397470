.checkoutProduct {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkoutProduct__info {
  padding-left: 20px;
  margin: 15px;
  font-family: sans-serif, Verdana, Tahoma;
  justify-content: center;
  /* color:cadetblue; */
  color: rgb(7, 68, 107);
  font-size: 2.2rem;
  font-weight: lighter;
}
.checkoutProduct__price {
  margin: 5, 5;
  color: #080101;
  font-weight: 100;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
}
.checkoutProduct__info > button {
  color: #111;
  background: #f0c14b;
  border: 0.5px solid;
  border-color: #efb82c #efb82c #efb82c;
  /* border-color: #a88734 #9c7e31 #846a29; */
  margin-top: 10px; /* space between product img and button */
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
  width: 150px;
  /* background: #f0c14b; */
  /* border:1px solid; */
  /* margin-top: 10px; */
  /* border-color:#a88734 #9c7e31 #846a29; */
  /* color: #111; */
  /* cursor:pointer; */
  /* font: size 10px; */
  /* padding:5px; */
}

.checkoutProduct__image {
  object-fit: contain;
  width: 120px;
  height: 120px;
}

.checkoutProduct__rating {
  display: flex;
  margin: 10px;
  font-size: medium;
}
.checkoutProduct__ratings {
  font-size: small;
}
.checkoutProduct__title {
  font-size: 0.8rem;
  font-weight: 300;
}
