* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #f79706ce;
  /* background-color:#394c2cce; */
  /* stick (unmovable) to the top , when user scrolls the page:*/
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgb(183, 24, 24),
    rgb(28, 20, 62)
  );
  mask-image: linear-gradient(to bottom, rgb(183, 24, 24), rgb(28, 20, 62));
  position: -webkit-fixed;
  position: fixed;
  top: 20;
  z-index: 100;
}
.header_logo {
  width: 100px;
  object-fit: contain; /* keep the aspect ratio */
  margin: 15px;
  margin-top: 18px;
}

.header_search {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 10px;
  border-radius: 2px;
  border: none;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
}

.header_searchInput {
  height: 45px;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.header_searchIconLeft_dropdown {
  padding: 5px;
  z-index: -1;
  margin-right: -5px;
  margin-left: 5px;
  height: 46px !important;
  width: 13%;
  background-color: rgb(238, 242, 246);
  display: flex;
  flex-direction: column;
  border-radius: 7px;
}
.header_searchIcon {
  padding-left: 3px;
  z-index: 1;
  margin-right: 15px;
  margin-left: -57px;
  height: 42px !important;
  width: 60px;
  background-color: #f0c14b;
  border-radius: 12px;
}

.header_searchResults {
  z-index: 110;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  width: 100px;
  padding: 10px;
  border: none;
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(200, 194, 194);
  position: -webkit-sticky;
  position: sticky;
}
.header_optionLineOne {
  font-size: 12px;
  padding: 0;
  font-weight: 400;
}
header_optionLineTwo {
  font-size: 13px;
  font-weight: 200;
  padding: 0;
}
.header_nav {
  display: flex;
  flex: 1;
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  /* align-items: center; */
  text-decoration: none;
  margin-left: 1px;
}
Link {
  text-decoration: none;
}

.header_option {
  font-weight: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-left: 50px;
  margin-top: 1px;
  text-decoration: none;
}
.header_Shop_basket {
  font-weight: 100px;
  color: rgb(136, 7, 7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  padding-right: 10px;
  text-decoration: none;
}
.header_optionBasket {
  color: white;
  display: flex;
  flex-direction: column;
}
.header_basketCount {
  margin-left: 10px;
  margin-right: 50px; /*10px*/
  text-align: left;
}
