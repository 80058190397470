.login {
  height: 100vh; /* 100 view height */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  /* background-color: white; */
  background-color: rgb(202, 56, 56);
}
.login_logo {
  margin-top: 15px;
  margin-bottom: 15px;
  object-fit: contain; /* keeps the aspect ratio during resizing */
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}
.login_container {
  width: 300px;
  height: fit-content; /* keeps the aspect ratio during resizing */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: 15px 30px 94px #1a1515;
  padding: 20px;
  z-index: 101;
}
.login_container > h1 {
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 20px;
}
.login_container > form > h5 {
  margin-bottom: 5px;
}
.login_container > form > input {
  height: 30px;
  margin-bottom: 10px;
  /* background-color: white; */
  width: 98%; /* occupy 98% of the container div */
}
.login_container > form > p {
  margin-top: 15px;
  font-size: 12px;
}
.login_signInButton {
  background: #f0c14b;
  border-radius: 2px;
  width: 100%; /* occupy 100% of the form width */
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
}
.login_registerButton {
  border-radius: 2px;
  width: 100%; /* occupy 100% of the form width */
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
}
.login_needHelp,
.login_forgotPassword,
.login_otherIssues {
  color: cornflowerblue;
  text-decoration: none;
  font-weight: 600;
}
