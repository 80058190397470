.product {
  display: flex;
  flex-direction: column;
  align-items: center; /* puts the whole product div to center of screeen */
  justify-content: flex-end;
  margin: 1px;
  padding: 8px;
  width: 100%;
  gap: 13px;
  max-height: 400px;
  min-width: 100px;
  background-color: white;
  border-radius: 10px;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgb(183, 24, 24),
    rgb(28, 20, 62)
  );
  mask-image: linear-gradient(to bottom, rgb(183, 24, 24), rgb(28, 20, 62));
  z-index: 1; /* pop up in front*/
}
.product_rating {
  display: flex;
}
.product > img {
  max-height: 150px;
  width: 100%;
  object-fit: contain; /* shrinks the image*/
  margin-top: 20px;
}
.product_img {
  padding-top: 50px;
  object-fit: contain; /* shrinks the image*/
  margin-top: 20px;
}
.product_info {
  /* space between start and product image*/
  height: 100px;
  margin: 15px;
  font-family: sans-serif, Verdana, Tahoma, Geneva;
  justify-content: center;
  /* color:cadetblue; */
  color: rgb(4, 74, 74);
  font-size: 1rem;
  font-weight: lighter;
}

.star_color {
  color: rgb(251, 121, 7);
  font-weight: bolder;
}
.product_price {
  margin: 5, 5;
  color: #575555;
  font-weight: 100;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.product > button {
  color: #111;
  background: #f0c14b;
  border: 0.5px solid;
  border-color: #efb82c #efb82c #efb82c;
  /* border-color: #a88734 #9c7e31 #846a29; */
  margin-top: 50px; /* space between product img and button */
  margin-bottom: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
}
.addToCart {
  color: #111;
  background: #f0c14b;
  border: 0.5px solid;
  border-color: #efb82c #efb82c #efb82c;
  /* border-color: #a88734 #9c7e31 #846a29; */
  margin-top: 70px; /* space between product img and button */
  margin-bottom: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
}
