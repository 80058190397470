.product_detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; /* puts the whole product div to center of screeen */
  justify-content: flex-end;
  margin: 1px;
  padding: 2px;
  width: 100%;
  /* max-height: 400px; */
  min-width: 100px;
  background-color: white;
  border-radius: 10px;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgb(183, 24, 24),
    rgb(28, 20, 62)
  );
  mask-image: linear-gradient(to bottom, rgb(183, 24, 24), rgb(28, 20, 62));
  z-index: 1; /* pop up in front*/
}
.product_detail_rating {
  margin: 50px;
  display: flex;
}
.product_detail_img {
  max-height: 70%;
  width: 70%;
  object-fit: contain; /* shrinks the image*/
  margin-top: 2px;
  padding: 0;
}
.product_detail_info {
  /* space between start and product image*/
  /* height: 100px; */
  margin: 15px;
  font-family: Ember;
  justify-content: center;
  /* color:cadetblue; */
  color: rgb(4, 74, 74);
  /* font-size: 1.5rem; */
  font-size: 22px;

  font-weight: 100;
}

.product_shipping_info {
  /* space between start and product image*/
  height: 100px;
  margin-top: 10px;
  font-family: sans-serif, Verdana, Tahoma, Geneva;
  justify-content: center;
  color: rgb(23, 24, 24);
  font-size: 1rem;
  font-weight: 300;
}
.star_color {
  color: rgb(251, 121, 7);
  font-weight: bolder;
  fill-opacity: inherit;
}
.product_detail_price {
  margin-top: 150;
  padding: 10px;
  color: #57807d;
  font-weight: 100;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.product_detail_measurement_items {
  width: 200px;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  padding: 0;
}
.product_detail_measurement_type {
  font-weight: 600;
  color: "black";
  padding: 0px;
}
.product_detail_measurement_value {
  font-weight: 400;
  color: "black";
  padding: 0px;
}
.product_detail_button {
  width: 200px;
  color: #111;
  background: #f0c14b;
  border: 0.5px solid;
  border-color: #efb82c #efb82c #efb82c;
  /* border-color: #a88734 #9c7e31 #846a29; */
  margin-top: 50px; /* space between product img and button */
  margin-bottom: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
}
