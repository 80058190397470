.emailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.email {
  display: flex;
  flex-direction: column;
}
input,
textarea {
  margin: 10px 0px;
}
