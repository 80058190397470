.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
  height: 300px;
  padding: 5px;
  padding-top: 35px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding-bottom: 150px;
}
.subtotal__gift {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
}

.subtotal__gift > input {
  margin-left: 5px;
  text-align: left;
}

.subtotal > button {
  background: #f0c14b;
  border-radius: 2px;
  width: 100%;
  font-weight: 700;
  height: 150px;
  border: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 15px;
  padding-bottom: 15px; */
  /* margin-top: 10px;
  margin-bottom: 10px; */
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  border-radius: 5px;
}
.checkOutLabel {
  color: red;
  margin-top: 70px;
  font-weight: 900;
  font-size: 14px;
}
.please-wait {
  color: rgb(223, 67, 5);
  margin-top: 30px;
  margin-left: 10px;
  font-weight: 900;
  font-size: 14px;
}
