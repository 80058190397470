*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.home{
    /* to avoid over streching*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* max-width: 1500px; */
    
    /* background-color: rgb(247, 245, 245); */
     background-color: rgb(1, 1, 1); 
    /* background-color: rgb(219, 232, 240); */
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 5, 0, 1), rgba(0, 0, 0, 1)); 
    mask-image: linear-gradient(to bottom, rgba(0, 5, 0, 1), rgba(0, 0, 0, 1));
   
}

.home__row
{
    display: flex;
    z-index: 1; /* combine with flex this puts the images side by side*/
     margin-left: 5px; 
     margin-right: 5px; 
     width:"100%"; 
    /* margin: auto; */
}

.home_image{
    width:98%;
    z-index: -1;
    margin-bottom: -380px;   
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); 
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));  /* fade outs*/
   
}
.home_slidesShow_image{
width:100%;
  height: auto;
    z-index: -1;   
    justify-content: center;
    align-items: center;
    margin-bottom: -280px;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1)); 
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));
   
}
@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }